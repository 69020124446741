import React, { useState } from 'react';

import { MenuItem as StyledMenuItem } from './styles';
import { useNavService } from 'services/NavService';

const MenuItem = ({ menuItem, ...others }) => {
  const [showGradient, setShowGradient] = useState(false);
  const { isDesktopMenuHovered } = useNavService();

  const handleClick = () => {};

  return (
    <StyledMenuItem
      aria-label={menuItem.categoryTitle}
      data-gradient={showGradient}
      data-menu-hovered={isDesktopMenuHovered}
      id={menuItem.id}
      key={menuItem.id}
      title={menuItem.categoryTitle}
      onClick={handleClick}
      onMouseEnter={() => setShowGradient(true)}
      onMouseLeave={() => setShowGradient(false)}
      {...others}
    >
      <span>
        <menuItem.icon />
      </span>
      <b>{menuItem.title}</b>
    </StyledMenuItem>
  );
};

export default MenuItem;
