import React, { useEffect, useState } from 'react';
import People from './People';
import Search from './Search';
import Experts from './Experts';
import TabNavigation from 'common/components/TabNavigation';
import HBFLayout from 'common/components/HBFLayout';
import IconExperts from 'assets/icons/Icon_Experts.svg';
import IconSearch from 'assets/icons/Icon_Search.svg';
import useOpenCloseTracking from 'services/TrackingService/useOpenCloseTracking';
import { marginTopAndButton } from '../../styles';
import { closeButtonSize } from '../styles';
import { useTranslate } from 'services/TranslationService/hooks';

function PeopleMenu({ updateSize, closeMenu }) {
  const [activeTab, setActiveTab] = useState('people');
  const showPeople = true;
  const translate = useTranslate();

  useEffect(updateSize, [activeTab]);

  useOpenCloseTracking('People');

  const header = showPeople ? (
    <>
      <TabNavigation
        tabs={[
          { id: 'people', title: translate('people.tab.people') || 'People', icon: IconSearch },
          {
            id: 'experts',
            title: translate('people.tab.experts') || 'Hosts',
            icon: IconExperts,
          },
        ]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {activeTab === 'people' && <Search />}
    </>
  ) : null;

  const body =
    showPeople && activeTab === 'people' ? (
      <People updateSize={updateSize} closeMenu={closeMenu} />
    ) : (
      <Experts showJoinButtons={!showPeople} updateSize={updateSize} closeMenu={closeMenu} />
    );

  return <HBFLayout header={header} body={body} fullHeightMinus={marginTopAndButton * 2 + closeButtonSize} />;
}

export default PeopleMenu;
