const languages = [
  { id: 'en_US', name: 'English' },
  { id: 'fr_FR', name: 'French' },
  { id: 'de_DE', name: 'German' },
  { id: 'es_ES', name: 'Spanish' },
  { id: 'pt_PT', name: 'Portuguese' },
  { id: 'pl_PL', name: 'Polish' },
  { id: 'it_IT', name: 'Italian' },
  { id: 'hu_HU', name: 'Hungarian' },
  { id: 'ro_RO', name: 'Romanian' },
  { id: 'he_HE', name: 'Greek' },
  { id: 'cz_CZ', name: 'Czech' },
  { id: 'hr_HR', name: 'Croatian' },
  { id: 'bg_BG', name: 'Bulgarian' },
  { id: 'mk_MK', name: 'Macedonian' },
  { id: 'sr', name: 'Serbian' },
  { id: 'sl_SL', name: 'Slovenian' },
  { id: 'sq_SQ', name: 'Albanian' },
];

export { languages };
