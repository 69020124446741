import { BurgerContainer, Line } from './styles';

export default function BurgerButton({ handleClick, show }) {
  return (
    <BurgerContainer show={show} onClick={handleClick}>
      <Line top />
      <Line middle />
      <Line bottom />
    </BurgerContainer>
  );
}
