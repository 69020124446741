import styled from 'styled-components';

export const Menu = styled.menu`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.7rem;
  margin: 0;
  padding: 0;
  width: 100%;
`;
