import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { createRoot } from 'react-dom/client';
import BouncePage from 'components/BouncePage';
import ErrorFallbackUI from 'components/ErrorFallbackUI';
import Start from 'components/Start';

import { useProfilingStore } from 'services/ProfilingService';

import detectIE from 'utilities/userAgent';
import axios from 'axios';
import { Object3D } from 'three';

// optimize updateMatrixWorld for avatar performance reasons
Object3D.prototype.updateMatrixWorld = function(force) {
  if (this.matrixAutoUpdate) this.updateMatrix();

  if (this.matrixWorldNeedsUpdate || force) {
    if (this.parent === null) {
      this.matrixWorld.copy(this.matrix);
    } else {
      this.matrixWorld.multiplyMatrices(this.parent.matrixWorld, this.matrix);
    }

    this.matrixWorldNeedsUpdate = false;

    force = true;
  }

  // update children

  const children = this.children;

  for (let i = 0, l = children.length; i < l; i++) {
    const child = children[i];

    // patch: only update children if they are visible if they have dontRenderIfInvisible
    if ((child.matrixWorldAutoUpdate === true || force === true) && !(child.dontRenderIfInvisible && !child.visible)) {
      child.updateMatrixWorld(force);
    }
  }
};

useProfilingStore.getState().init();

const container = document.createElement('div');
container.className = 'application';
document.body.appendChild(container);

function ErrorFallback({ error }) {
  return <ErrorFallbackUI message={error.message} />;
}

export const LANDING_URL = window.location.href;
const showFallBackPage = detectIE();
const fallBack = <BouncePage />;
const app = (
  <>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Start />
    </ErrorBoundary>
  </>
);
const result = showFallBackPage ? fallBack : app;

axios.get('https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/incr_stage_passed?stage=start');

const root = createRoot(container);
root.render(result);

export default Start;
