import styled, { css } from 'styled-components';

import { Button } from 'uiVe/common/components/Button/styles';

import { expandMenu } from 'uiVe/styles/mixins';
import { mediaMax, mediaMin } from 'common/layout';

export const ButtonChat = styled(Button)`
  color: white;
  height: var(--menu-width);
  width: 100%;

  svg {
    width: var(--min-hit-size);
  }

  ${mediaMax(
    'small',
    css`
      b {
        display: none;
      }
    `
  )}

  ${mediaMin(
    'medium',
    css`
      ${expandMenu};

      align-items: center;
      gap: 2rem;
      display: grid;
      grid-template-columns: var(--menu-width) 1fr;

      span {
        align-items: center;
        border-top: 0.1rem solid rgba(255, 255, 255, 0.15);
        display: flex;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    `
  )}
`;
