import React, { useEffect } from 'react';

import { useNavService } from 'services/NavService';

import { ButtonMenu as StyledButtonMenu } from './styles';

import IconClose from 'uiVe/assets/icons/Icon_Close.svg';
import IconMenu from 'uiVe/assets/icons/Icon_Menu.svg';

const ButtonMenu = ({ ...others }) => {
  const { isMobileMenuOpen, toggleMobileMenu } = useNavService();

  return (
    <StyledButtonMenu onClick={toggleMobileMenu} {...others}>
      {isMobileMenuOpen ? <IconClose /> : <IconMenu />}
    </StyledButtonMenu>
  );
};

export default ButtonMenu;
