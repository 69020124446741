import { useTranslate } from 'services/TranslationService/hooks';
import VeTextLink from 'common/components/VeTextLink';

function ImageUploadButton({ onImageSelected }) {
  const translate = useTranslate();
  return (
    <VeTextLink
      data-track-category="Profile"
      data-track-action={'UploadPicture'}
      small
      accent
      as={'button'}
      style={{ width: '50%', marginRight: '4px' }}
      onClick={() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.jpg, .jpeg, .png, .webp, .tif, .tiff, .gif, .svg';
        document.body.appendChild(input);
        input.onchange = e => {
          document.body.removeChild(input);
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = readerEvent => {
            const content = readerEvent.target.result;
            onImageSelected(content);
          };
        };
        input.click();
      }}
    >
      {translate('profile.picture.upload') || 'Upload'}
    </VeTextLink>
  );
}

export default ImageUploadButton;
