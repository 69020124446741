import { Path } from './styles';

const Logo = ({ filled }) => (
  <svg viewBox="0 0 27 29" xmlns="http://www.w3.org/2000/svg">
    <Path
      filled={filled}
      d="m3.218 6.846-.003.002c-.43.243-.719.643-.885.982-.167.338-.309.813-.235 1.307v.001l1.688 11.31v.001c.072.48.302.962.537 1.336.236.373.573.789.974 1.06l6.868 4.642.003.002c.411.276.902.36 1.284.364.382.004.876-.07 1.295-.34l.003-.002 7.198-4.662c.409-.265.745-.677.976-1.044.232-.369.457-.848.521-1.33v-.003l1.465-11.333v-.003c.063-.492-.085-.966-.256-1.305-.171-.338-.465-.74-.9-.982l-8.954-4.983-.003-.001c-.428-.236-.937-.308-1.343-.308-.405 0-.924.07-1.36.316l-.002.002-8.871 4.97Z"
    />
  </svg>
);

export default Logo;
