import { BlockTitle, LightSwitchOptionContainer, ProfileInfo, ProfileInfoSection, ProfileInfoTitle } from '../styles';
import ToggleButton from 'common/components/ToggleButton';

import LinkedInIcon from 'assets/Logo_LinkedIn.svg';
import { useTranslate } from 'services/TranslationService/hooks';
import { useUserStore } from 'services/UserService';
import useIsQuickRegistration from 'uiLivi/components/Menu/SubMenu/ProfileMenu/useIsQuickRegistration';
import RenderQualityPanel from 'uiLivi/components/Menu/SubMenu/DevMenu/RenderQualityPanel';
import { useQualitySettings } from 'services/QualitySettings';

function UserView({ user, isSelf, playerIsExpert }) {
  const isQuickRegistration = useIsQuickRegistration(user);
  const defaultQualityOption = useQualitySettings(state => state.options.find(q => q.default));

  const emailVisible = playerIsExpert;
  const linkedInVisible = user.linkedInUrl;
  const hasContactInformation = emailVisible || linkedInVisible;
  const translate = useTranslate();

  const handleNotificationUpdate = async hasNotifications => {
    const values = {
      hasNotifications,
    };
    try {
      await useUserStore.getState().updateProfile(values);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error.message);
    }
  };

  return (
    <>
      {hasContactInformation && (
        <ProfileInfoSection>
          <BlockTitle>{translate('profile.contact') || 'Contact'}</BlockTitle>
          {emailVisible && <ProfileInfoTitle>{translate('profile.mail') || 'Email'}</ProfileInfoTitle>}
          {emailVisible && <ProfileInfo style={{ userSelect: 'text' }}>{user.email}</ProfileInfo>}
          {linkedInVisible && <ProfileInfoTitle>{translate('profile.social') || 'Social Media'}</ProfileInfoTitle>}
          {linkedInVisible && (
            <LinkedInIcon
              style={{ cursor: 'pointer', marginTop: '5px' }}
              onClick={() => {
                window.open(user.linkedInUrl, '_blank');
              }}
            />
          )}
        </ProfileInfoSection>
      )}
      {isSelf && (
        <>
          {!isQuickRegistration ? (
            <ProfileInfoSection>
              <BlockTitle>{translate('profile.account') || 'Account'}</BlockTitle>
              <ProfileInfoTitle>{translate('profile.mail') || 'Email'}</ProfileInfoTitle>
              <ProfileInfo>{user.email}</ProfileInfo>
              <ProfileInfoTitle>{translate('profile.password.headline') || 'Password'}</ProfileInfoTitle>
              <ProfileInfo> ● ● ● ● ● ● ●</ProfileInfo>
            </ProfileInfoSection>
          ) : null}
          <ProfileInfoSection>
            <BlockTitle>{translate('profile.settings.headline') || 'Profile Settings'}</BlockTitle>
            <LightSwitchOptionContainer>
              <ProfileInfo>{translate('profile.notifications.notification') || 'Enable Notifications'}</ProfileInfo>
              <ToggleButton active={user.hasNotifications} onToggle={handleNotificationUpdate} />
            </LightSwitchOptionContainer>
          </ProfileInfoSection>
        </>
      )}
    </>
  );
}

export default UserView;
