import React, { useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { useUserStore } from 'services/UserService';
import { useEventStore } from 'services/EventService';
import Play from 'components/Play';
import Config from 'components/DevContent/Config';
import Login, { loginPathsAndComponents } from 'components/Login';
import CreateAvatar from 'components/CreateAvatar';
import ConfirmationPage from 'components/ConfirmationPage';
import MeetingReservationRedirector from '../MeetingReservationRedirector';

export default function Routes() {
  const event = useEventStore(state => state.event);
  const startDistrict = event.districts?.[0]?.room;

  const isPending = useUserStore(state => state.loginState === 'PENDING');
  const isLoggedIn = useUserStore(state => state.loginState === 'LOGGED_IN');
  const hasAppearance = useUserStore(state => state.player.user.appearance != null);
  const eventLive = useEventStore(state => state.eventLive);
  const userIsExpert = useUserStore(state => state.user?.role?.type === 'expert');

  const sharedRoutes = [<Route key="config" path="/config" component={Config} />];
  const sharedRoutesLoggedIn = [...sharedRoutes, <Route key="avatar" exact path="/profile" component={CreateAvatar} />];

  // 1. Register || Login
  // 2. Avatar (always!)
  // 3. event live ? Lobby : Confirmation (Countdown)

  const loginPaths = useMemo(() => loginPathsAndComponents.map(lpac => lpac.path), []);

  return (
    <Router>
      <Switch>
        {!isLoggedIn
          ? [
              ...sharedRoutes,
              <Route key="login" exact path={loginPaths} component={Login} />,
              <Redirect key="redirect" to="/join" />,
            ]
          : !hasAppearance
          ? [...sharedRoutesLoggedIn, <Redirect key="redirect" to="/profile" />]
          : !eventLive && !isPending && !userIsExpert
          ? [
              ...sharedRoutesLoggedIn,
              <Route key="confirmation" exact path="/confirmation" component={ConfirmationPage} />,
              <Redirect key="redirect" to="/confirmation" />,
            ]
          : [
              ...sharedRoutesLoggedIn,
              <Route key="confirmation" exact path="/confirmationtest" component={ConfirmationPage} />,
              <Route
                key="meeting"
                exact
                path="/meeting/:reservationId"
                render={props => <MeetingReservationRedirector {...props} to="/" />}
              />,
              <Route key="play" path="/play/:mapId/" component={Play} />,
              <Redirect key="redirect" to={`/play/${startDistrict}`} />,
            ]}
      </Switch>
    </Router>
  );
}
