import styled from 'styled-components';
import { clearButtonStyles } from 'uiVe/styles/mixins';

export const StyledReactions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
  width: var(--menu-width);
  @media only screen and (max-height: 700px) {
    flex-direction: row;
  }
`;

export const ReactionButton = styled.button`
  ${clearButtonStyles};

  font-size: 2rem;
  height: var(--min-hit-size);
  width: var(--min-hit-size);

  @media only screen and (max-height: 700px) {
    // adds left spacing to centralize the first button with the menu column
    &:first-child {
      margin-left: calc((var(--menu-width) - var(--min-hit-size)) / 2);
    }
  }
`;
