import React, { useEffect } from 'react';
import { useExpertStore } from 'services/ExpertService';
import { useUserStore } from 'services/UserService';
import { createContentFromTalk, useContentStore } from 'services/ContentService';
import { useTalkStore } from 'services/TalkService';
import { FullName, GreySendIcon, ListContainer, ListHeadline, LiveSessionIndicator, Title } from '../People/styles';
import { useTrackingStore } from 'services/TrackingService';
import { useChatStore } from 'services/ChatService';
import OnlineIndicator from 'common/components/OnlineIndicator';
import Person from 'common/components/Person';
import { Button } from 'common/components/Button';
import { useTranslate } from 'services/TranslationService/hooks';

export default function Experts({ showJoinButtons, updateSize, closeMenu }) {
  const experts = useExpertStore(state => state.experts);
  const user = useUserStore(state => state.user);
  const translate = useTranslate();

  useEffect(() => {
    useExpertStore.getState().requestExperts();
  }, []);

  useEffect(updateSize, [experts]);

  const onlineExperts = experts.filter(e => e.isOnline).filter(e => e.id !== user.id);
  const offlineExperts = experts.filter(e => !e.isOnline).filter(e => e.id !== user.id);

  const joinLiveSessionButtonClick = activity => {
    const { setActiveContent } = useContentStore.getState();
    const talk = useTalkStore.getState().talks.find(talk => talk.title === activity.label);
    if (talk == null) {
      // eslint-disable-next-line no-console
      return console.error('could not find the talk that matches the title from the activity');
    }
    const liveSessionContent = createContentFromTalk(talk);
    setActiveContent(liveSessionContent);
  };

  const renderListWithTitle = (expertList, title) => {
    return (
      <>
        {expertList.length > 0 && <ListHeadline>{title}</ListHeadline>}
        {renderList(expertList)}
      </>
    );
  };

  const renderList = expertList => {
    return expertList.map(expert => {
      const livesession =
        !!expert.activity && (expert.activity.type === 'DEMO' || expert.activity.type === 'LIVESTREAM');
      return (
        <Person
          margin={'15px 0 0 0 '}
          key={expert.id}
          image={{ src: expert.profilePicture, size: 'm' }}
          isExpert
          infoComponents={[
            <FullName
              key="name"
              onClick={() => {
                useUserStore.getState().selectUserById(expert.id);
                useTrackingStore
                  .getState()
                  .trackEvent({ category: 'People', action: 'ViewUserProfile', label: '' + expert.id });
              }}
            >
              {expert.forename} {expert.surname}
              <OnlineIndicator active={expert.isOnline} />
            </FullName>,
            <Title key={'title'}>{expert.title}</Title>,
            livesession && (
              <LiveSessionIndicator key={'liveSessionIndicator'}>
                {translate('people.category.islive') || '@Live Session'}
              </LiveSessionIndicator>
            ),
            showJoinButtons && livesession && (
              <Button
                key={'joinButton'}
                accent
                small
                onClick={() => {
                  joinLiveSessionButtonClick(expert.activity);
                }}
              >
                {translate('people.category.join') || 'Join'}
              </Button>
            ),
          ]}
          actionComponents={[
            <GreySendIcon
              key="context"
              onClick={() => {
                closeMenu();
                useChatStore.getState().start([expert.id]);
                useTrackingStore
                  .getState()
                  .trackEvent({ category: 'People', action: 'StartChat', label: '' + expert.id });
              }}
            />,
          ]}
        />
      );
    });
  };

  return (
    <ListContainer>
      {renderListWithTitle(onlineExperts, translate('people.category.online') || 'Online')}
      {renderListWithTitle(offlineExperts, translate('people.category.offline') || 'Offline')}
    </ListContainer>
  );
}
