import { ActiveIndicator, IconContainer, Item, TextContainer } from './styles';

export default function MenuItem({ active = false, mI, clickHandler }) {
  return (
    <Item
      active={active}
      onClick={() => {
        clickHandler(mI.id);
      }}
    >
      <ActiveIndicator />
      {mI.icon && (
        <IconContainer active={active}>
          <mI.icon />
        </IconContainer>
      )}
      <TextContainer active={active}>{mI.categoryTitle}</TextContainer>
    </Item>
  );
}
