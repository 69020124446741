import React, { useState } from 'react';

import ExpandableInput from 'uiVe/common/Input/InputExpandable';
import ButtonExpand from 'uiVe/common/components/Button/ButtonExpand';
import PanelCloseButton from 'uiVe/components/Panel/PanelCloseButton';

import { PanelButtons } from 'uiVe/components/Panel/styles';
import { PanelChat as StyledPanelChat, ChatContent, ChatInput } from './styles';

// import { useChatStore } from 'services/ChatService';

import IconPlane from 'uiVe/assets/icons/Icon_Plane.svg';

const PanelChat = ({ ...others }) => {
  const [expanded, setExpanded] = useState(false);
  // const { activeChat, chats } = useChatStore(state => ({
  //   activeChat: state.activeChat,
  //   chats: [...state.chats].reverse(),
  // }));

  const handleSubmitMessage = () => {};

  return (
    <StyledPanelChat data-expanded={expanded} {...others}>
      <PanelButtons>
        <ButtonExpand expanded={expanded} onClick={() => setExpanded(exp => !exp)} />
        <PanelCloseButton />
      </PanelButtons>

      <ChatContent>messages go here</ChatContent>
      <ChatInput>
        <ExpandableInput
          icon={<IconPlane />}
          buttonTitle="Send message"
          placeholder="press enter ↩ to type"
          onSubmit={handleSubmitMessage}
        />
      </ChatInput>
    </StyledPanelChat>
  );
};

export default PanelChat;
