import React from 'react';

import { useNavService } from 'services/NavService';

import { PanelCloseButton as StyledPanelCloseButton } from './styles';

import IconClose from 'uiVe/assets/icons/Icon_Close.svg';

const PanelCloseButton = () => {
  const { closeAllPanels } = useNavService();

  return (
    <StyledPanelCloseButton onClick={() => closeAllPanels()}>
      <IconClose />
    </StyledPanelCloseButton>
  );
};

export default PanelCloseButton;
