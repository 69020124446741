import { usePeopleStore } from 'services/PeopleService';
import { FormContainer } from '../styles';
import { Input } from 'common/components/Input';
import { useTranslate } from 'services/TranslationService/hooks';

export default function Search() {
  const search = usePeopleStore(state => state.search);
  const translate = useTranslate();
  return (
    <FormContainer>
      <Input
        onChange={e => usePeopleStore.getState().setSearchValue(e.target.value)}
        value={search}
        placeholder={translate('people.search.placeholder') || 'Search ...'}
      />
    </FormContainer>
  );
}
