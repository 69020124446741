import styled from 'styled-components';

import { Button } from 'uiVe/common/components/Button/styles';

export const PanelCloseButton = styled(Button)`
  color: inherit;
  line-height: 0;

  svg {
    aspect-ratio: 1;
    height: 4rem;
  }
`;
