import { Item } from '../styles';
import { useDebugStore } from 'storage/debug';

export default function UiPanel() {
  const ui = useDebugStore(state => state.getUi());

  const options = [
    { label: 'Off', value: 'off', selected: ui.uiOff },
    { label: 'Livi', value: 'livi', selected: ui.uiLivi },
    { label: 'VE', value: 've', selected: ui.uiVe },
  ];
  return (
    <>
      {options.map((o, i) => {
        return (
          <Item
            key={i}
            selected={o.selected}
            onClick={() => {
              useDebugStore.getState().setUi(o.value);
              useDebugStore.setState({});
            }}
          >
            {o.label}
          </Item>
        );
      })}
    </>
  );
}
