import styled, { css, keyframes } from 'styled-components';
import { mediaMaxPx } from 'common/layout';

export const LinkContainer = styled.a`
  align-items: center;
  display: flex;
  font-weight: 300;
  gap: 0.6rem;
  overflow: hidden;
  position: absolute;
  text-transform: uppercase;
  transition: max-width 0.3s ease-in;
  white-space: nowrap;

  ${mediaMaxPx(
    520,
    css`
      font-size: 0.8rem;
    `
  )}
`;

export const Logo = styled.div`
  svg {
    fill: transparent;
    stroke: white;
    stroke-width: 2.2;
    transition: fill 0.2s linear;
    width: 1.8rem;
  }

  [data-hovered='true'] & {
    svg {
      fill: white;
    }
  }
`;

const expand = keyframes`
0% {
  max-width: 0;
  opacity: 0;
}
75% {
  opacity: 0;
  max-width: 99rem;
}
100% {
  opacity: 1;
  max-width: 99rem;
}
`;

const retract = keyframes`
0% {
  opacity: 1;
  max-width: 99rem;
}
75% {
  opacity: 0;
  max-width: 99rem;
}
100% {
  max-width: 0;
  opacity: 0;
}
`;

export const ExpandableText = styled.span`
  animation-duration: 0.5s;
  color: var(--grey-medium);

  [data-hovered='false'] & {
    animation-delay: 5s;
    animation-fill-mode: forwards;
    animation-name: ${retract};
  }
  [data-hovered='true'] & {
    animation-fill-mode: forwards;
    animation-name: ${expand};
  }

  b {
    color: var(--color-font-strong);
    letter-spacing: 0.1rem;
    margin-left: 0.6rem;
  }
`;
