import {
  IconContainer,
  InstructionHeadline,
  InstructionText,
  InstructionTopline,
  InstructionVisualBox,
  InstructionVisualColumn,
  InstructionVisualContainer,
  InstructionWrapper,
} from '../styles';
import IconProfile from '../assets/profile.png';
import React from 'react';

export default function OnboardingProfile() {
  return (
    <InstructionWrapper>
      <InstructionTopline>Get Started</InstructionTopline>
      <InstructionHeadline>Make it personal</InstructionHeadline>
      <InstructionText>
        Share a little more about yourself in the profile section. Adding your job title and country help you connect
        better with other participants.
      </InstructionText>
      <InstructionVisualContainer>
        <InstructionVisualColumn grow>
          <InstructionVisualBox>
            <IconContainer icon={IconProfile} />
          </InstructionVisualBox>
        </InstructionVisualColumn>
      </InstructionVisualContainer>
    </InstructionWrapper>
  );
}
