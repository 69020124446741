import styled from 'styled-components';

import { Button } from 'uiVe/common/components/Button/styles';

export const ButtonExpand = styled(Button)`
  align-items: center;
  color: inherit;
  display: flex;
  line-height: 1em;

  svg {
    aspect-ratio: 1;
    width: 2rem;
  }
`;
