import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from 'common/layout';

import { Button } from 'uiVe/common/components/Button/styles';
import { expandMenu } from 'uiVe/styles/mixins';

export const MenuItem = styled(Button)`
  align-items: center;
  color: white;
  display: flex;
  gap: 0;
  position: relative;
  width: 100%;

  ${mediaMin(
    'medium',
    css`
      ${expandMenu};
      gap: 2rem;
      display: grid;
      grid-template-columns: var(--menu-width) 1fr;

      span {
        width: 100%;
      }
    `
  )}

  svg {
    width: var(--min-hit-size);

    ${mediaMax(
      'small',
      css`
        opacity: 50%;
      `
    )}
  }

  &:before {
    content: '';
    background: var(--ltr-gradient);
    height: 100%;
    left: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: transform 0.4s ease-in-out;
    top: 0;
    visibility: hidden;
    width: 0.3rem;
  }

  &[data-gradient='true'] {
    &:before {
      visibility: visible;
      transform: translateX(0);
    }
  }
`;
