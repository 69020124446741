import React, { useEffect, useState } from 'react';

import MenuItem from 'uiVe/common/components/MenuItem';

import getItems from './menuItems';

import { useUserStore } from 'services/UserService';

import { Menu as StyledMenu } from './styles';

const Menu = ({ ...others }) => {
  const user = useUserStore(state => state.user);
  const [menuItems, setMenuItems] = useState();

  useEffect(() => {
    const menuItems = getItems(user.language);
    setMenuItems(menuItems);
  }, []);

  return (
    <StyledMenu {...others}>
      {menuItems?.map(menuItem => (
        <MenuItem key={menuItem.id} menuItem={menuItem} />
      ))}
    </StyledMenu>
  );
};

export default Menu;
