import DevContent from 'components/DevContent';
import Routes from 'components/Routes';
import StartMessage from 'components/Start/StartMessage';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useBuildStore } from 'services/BuildService';
import { useDeviceStore } from 'services/DeviceService';
import { useEventStore } from 'services/EventService';
import { useProfilingStore } from 'services/ProfilingService';
import { useQualitySettings } from 'services/QualitySettings';
import { useSocketStore } from 'services/SocketService';
import { useSoundStore } from 'services/SoundService';
import { useTrackingStore } from 'services/TrackingService';
import { useTranslate } from 'services/TranslationService/hooks';
import { useUserStore } from 'services/UserService';
import { useWindowStore } from 'services/WindowService';
import styled from 'styled-components';
import { colors, GlobalStyle as LiviGlobalStyle } from 'uiLivi/styles';
import { useHowlerStore } from 'services/HowlerService';
import HowlerUISounds from '../HowlerUISounds';
import { useDebugStore } from 'storage/debug';
import { GlobalStyle as VeGlobalStyle } from 'uiVe/styles/styles';
import { useGpuDetect } from 'services/GpuDetectService';
import { useAssetDetails } from 'services/AssetDetailService';
import { useInterval } from '../../utilities/hooks';
import axios from 'axios';

const Connecting = styled.div`
  position: absolute;
  font-weight: bold;
  color: ${colors.font.light};
  text-align: center;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

function Start() {
  const [state, setState] = useState('INIT');
  const { uiLivi, uiVe, uiOff } = useDebugStore(state => state.getUi());
  const eventState = useEventStore(state => state.eventState);
  const loginState = useUserStore(state => state.loginState);
  const language = useUserStore(state => state.user?.language);
  const translate = useTranslate();

  useInterval(() => {
    if (useEventStore.getState().event) {
      const { shortname } = useEventStore.getState().event;
      axios.get(
        'https://metrics-collector.virtexp.virtual-events.dmdr.io/metric/' +
          shortname +
          '/incr_user_onlinetime_total?timeSpent=' +
          10
      );
    }
  }, 10000);

  useEffect(() => {
    useProfilingStore.getState().mark('Init');

    const initApis = async (skipRequests = false, hubId = null) => {
      useSoundStore.getState().init();
      useDeviceStore.getState().init();
      await useGpuDetect.getState().init();
      useAssetDetails.getState().init();
      useQualitySettings.getState().init();
      useWindowStore.getState().init();
      let skipLogin = false;
      if (skipRequests) {
        skipLogin = true;
        useEventStore.getState().setEventIsOverloaded();
      } else {
        useSocketStore.getState().selectEndpoint(hubId);
        try {
          useProfilingStore.getState().mark('Event Start Load');
          await useEventStore.getState().init();
          useHowlerStore.getState().init();
          useTrackingStore.getState().init();
          useProfilingStore.getState().mark('Event Loaded');
        } catch (e) {
          // do not attempt to login in case that socket connection failed
          skipLogin = true;
          // eslint-disable-next-line no-console
          e && console.error(e);
        }
      }
      useUserStore.getState().init(skipLogin);
    };

    const init = async () => {
      useBuildStore.getState().init();
      const { status, hubId } = await useBuildStore.getState().getBackendStatus();
      const fallbackIsActive = status !== 'available';
      if (fallbackIsActive || hubId === null) {
        initApis(true);
      } else {
        initApis(false, hubId);
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (!language) return;
    moment.locale(language);
  }, [language]);

  useEffect(() => {
    if (
      eventState === 'NONE_AVAILABLE' ||
      eventState === 'FAILED' ||
      eventState === 'OVERLOADED' ||
      loginState === 'FULL'
    ) {
      setState('MESSAGE');
    } else if (loginState === 'LOGGED_OUT' || loginState === 'LOGGED_IN') {
      setState('SOLVED');
    }
  }, [loginState, eventState]);

  const showMessage = state === 'MESSAGE';
  const connected = state === 'SOLVED';

  const mainComponents = connected ? (
    <>
      <HowlerUISounds />
      <Routes />
      <DevContent />
    </>
  ) : (
    <Connecting>{translate('loader.connecting') || 'Connecting'}</Connecting>
  );

  const content = showMessage ? <StartMessage /> : mainComponents;
  return (
    <>
      {(uiLivi || uiOff) && <LiviGlobalStyle />}
      {uiVe && <VeGlobalStyle />}
      {content}
    </>
  );
}

export default Start;
