import React, { useEffect, useState } from 'react';
import HBFLayout from 'common/components/HBFLayout';
import { Button } from 'common/components/Button';
import { useModalService } from 'services/ModalService';
import { useOnboardingStore } from 'services/OnboardingService';
import { ContentContainer, SkipButton, SlideTransitionWrapper, TitleContainer } from './styles';
import Pagination from './Pagination';
import SwipeDetect from './SwipeDetect';

export default function Onboarding({ onBoardingSlides }) {
  const [slideContent, setSlideContent] = useState(<></>);
  const [slideCount, setSlideCount] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [showSlide, setShowSlide] = useState(false);
  const lastSlide = slideIndex === slideCount - 1;

  useEffect(() => {
    setSlideContent(onBoardingSlides[0]);
    setSlideCount(onBoardingSlides.length);
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      setShowSlide(true);
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [slideIndex]);

  const closeModal = () => {
    useModalService.getState().closeModal();
    useOnboardingStore.getState().finishOnboarding();
  };

  const gotoSlide = index => {
    setShowSlide(false);
    setSlideIndex(index);
    setSlideContent(onBoardingSlides[index]);
  };

  const nextSlide = () => {
    if (slideIndex < slideCount - 1) {
      const nextIndex = slideIndex + 1;
      gotoSlide(nextIndex);
    }
  };

  const prevSlide = () => {
    if (slideIndex > 0) {
      const nextIndex = slideIndex - 1;
      gotoSlide(nextIndex);
    }
  };

  const onSwipeDetect = direction => {
    if (direction === -1) nextSlide();
    if (direction === 1) prevSlide();
  };

  const header = <TitleContainer>{!lastSlide && <SkipButton onClick={closeModal}>Skip</SkipButton>}</TitleContainer>;

  const body = (
    <ContentContainer>
      <SlideTransitionWrapper show={showSlide}>{slideContent}</SlideTransitionWrapper>
      <SwipeDetect onSwipeDetect={onSwipeDetect} />
      <Pagination slideCount={slideCount} slideIndex={slideIndex} gotoSlide={gotoSlide} />
    </ContentContainer>
  );

  const footer = (
    <Button accent noRadius veStyle fullWidth onClick={lastSlide ? closeModal : nextSlide}>
      {lastSlide ? 'Get Started!' : 'Next'}
    </Button>
  );

  return <HBFLayout header={header} body={body} footer={footer} />;
}
