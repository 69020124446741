import React, { useEffect } from 'react';
import { useUserStore } from 'services/UserService';
import Profile from './Profile';

function ProfileMenu({ updateSize }) {
  useEffect(() => {
    return () => {
      useUserStore.getState().selectUserById(null);
    };
  }, []);
  useEffect(updateSize, [updateSize]);

  const playerIsExpert = useUserStore(state => state.user.role.type === 'expert');
  const selectedUser = useUserStore(state => state.users.find(u => u.id === state.selectedUserId));
  const user = selectedUser ? selectedUser : useUserStore.getState().user;

  const tabs = null;

  return user ? <Profile tabs={tabs} updateSize={updateSize} user={user} playerIsExpert={playerIsExpert} /> : null;
}

export default ProfileMenu;
