import { mediaMaxPx } from 'common/layout';
import styled, { css } from 'styled-components';
import { colors, lgBaseColors, zIndex } from 'uiLivi/styles';

export const LinkContainer = styled.div.attrs(props => ({
  'data-sound-action': 'click',
}))`
  z-index: ${zIndex.poweredBy};
  align-items: center;
  position: absolute;
  display: flex;
  left: 20px;
  bottom: 20px;

  animation: fadeIn 1s;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  width: 18px;
  height: 22px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: ${lgBaseColors.grey.medium};
  transition: 0.3s ease-in;
  transition-delay: ${props => (props.expand ? 0 : 5)}s;
  transform: translateX(${props => (props.expand ? 0 : -20)}px);
  visibility: ${props => (props.expand ? 'visible' : 'hidden')};
  opacity: ${props => (props.expand ? 1 : 0)};
  font-size: 12px;
  margin-left: 16px;
  letter-spacing: 0px;

  b {
    color: ${colors.font.strong};
    letter-spacing: 1px;
    margin-left: 6px;
    font-weight: bolder;
  }

  ${mediaMaxPx(
    520,
    css`
      font-size: 8px;
    `
  )}
`;
