import React, { useEffect, useRef } from 'react';
import { useWindowStore } from 'services/WindowService';
import { useSpring } from 'react-spring';
import { CloseButtonContainer, closeButtonSize, ContentContainer, SubMenuContainer } from './styles';
import CloseButton from 'common/components/CloseButton';
import { marginTopAndButton } from '../styles';

export default function SubMenu({ menuItem, closeMenu, show }) {
  const isMobile = useWindowStore(state => state.isMobile);
  const vh = useWindowStore(state => state.vh);
  const [props, set] = useSpring(() => ({ width: 0, height: 0 }));
  const contentContainerRef = useRef();

  const hasSubComponent = menuItem && menuItem.subComponentFunction ? true : false;
  const showSubMenu = hasSubComponent && show;

  useEffect(() => {
    if (!showSubMenu) set({ width: 0, height: 0 });
  }, [showSubMenu]);

  useEffect(() => {
    if (show) updateSize();
  }, [show, isMobile, vh]);

  useEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [menuItem]);

  const updateSize = () => {
    const size = { width: contentContainerRef.current.offsetWidth, height: contentContainerRef.current.offsetHeight };
    const maxHeight = window.innerHeight - (isMobile ? 0 : marginTopAndButton * 2);
    size.height += showSubMenu ? closeButtonSize : 0;
    if (size.height > maxHeight) size.height = maxHeight;
    set(size);
  };

  return (
    <SubMenuContainer style={!isMobile ? props : {}} show={showSubMenu}>
      <CloseButtonContainer>
        {!isMobile && <CloseButton black={true} position={{ top: 0, right: 0 }} handler={closeMenu} />}
      </CloseButtonContainer>
      <ContentContainer
        key={menuItem ? menuItem.id : 'empty'}
        vh={vh}
        open={menuItem}
        wide={menuItem ? menuItem.wide : false}
        ref={contentContainerRef}
      >
        {hasSubComponent && <menuItem.subComponentFunction updateSize={updateSize} closeMenu={closeMenu} />}
      </ContentContainer>
    </SubMenuContainer>
  );
}
