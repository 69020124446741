import styled, { css } from 'styled-components';
import { hexToRgba } from 'utilities/color';
import { colors, lgBaseColors } from 'uiLivi/styles';
import { H3 } from 'common/text';
import { mediaMax } from 'common/layout';

const backgroundStyle = css`
  background-color: ${hexToRgba(colors.background.base, 0.85)};
  backdrop-filter: blur(4px);
`;

export const SkipButton = styled.div`
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  right: 15px;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  padding: 20px;
  text-align: center;
  ${backgroundStyle};
  color: ${colors.font.light};
  width: 350px;
  height: 325px;
  min-height: 100%;
  ${mediaMax(
    'small',
    css`
      width: 100vw;
      padding-top: 15vh;
    `
  )}
`;

export const TitleContainer = styled.div`
  position: relative;
  ${backgroundStyle};
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  color: ${colors.font.light};
`;

export const Title = styled(H3)`
  line-height: inherit;
  margin-top: 0;
  text-align: center;
`;

export const InstructionText = styled.div`
  display: inline-block;
  line-height: 22px;
  margin-top: 0;
  max-width: 350px;
`;

export const InstructionWrapper = styled.div`
  text-align: left;

  ${mediaMax(
    'small',
    css`
      text-align: center;
    `
  )}
`;

export const InstructionHeadline = styled.div`
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const InstructionTopline = styled.div`
  font-size: 10px;
  opacity: 0.5;
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.icon});
  background-size: contain;
  background-repeat: no-repeat;
  //border: 1px solid #1ed36f;
`;

export const InstructionVisualContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 50px;
  flex-wrap: nowrap;
  justify-content: center;
  //border: 1px solid green;
`;

export const InstructionVisualColumn = styled.div`
  width: 150px;
  position: relative;
`;

export const SpacerColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const InstructionVisualBox = styled.div`
  padding-top: 80%;
  position: relative;
  height: 150px;
  svg {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    transform: translateY(-50%);
  }
`;

export const InstructionSpacerBox = styled.div`
  background-color: transparent;
  color: ${lgBaseColors.grey.medium};
  padding: 0 20px;
  line-height: 100%;
  white-space: nowrap;
`;

export const InstructionVisualCaption = styled.div`
  position: absolute;
  width: 100%;
  bottom: -35px;
  padding: 10px;
  color: ${lgBaseColors.grey.medium};
`;

export const SlideTransitionWrapper = styled.div`
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity ${props => (props.show ? 0.5 : 0)}s ease-out;
`;
