import IconCalender from 'uiVe/assets/icons/Icon_Calender.svg';
// import IconDev from 'uiVe/assets/icons/Icon_Dev.svg';
import IconLocation from 'uiVe/assets/icons/Icon_Districts.svg';
import IconPeople from 'uiVe/assets/icons/Icon_People.svg';
import { useTranslationStore } from 'services/TranslationService';

const getItems = language => {
  const translate = key => useTranslationStore.getState().translate(key, language);
  return [
    {
      title: translate('menu.location.title') || 'Locations',
      id: 'locations',
      icon: IconLocation,
    },
    {
      title: translate('menu.schedule.title') || 'Schedule',
      id: 'timetable',
      icon: IconCalender,
    },
    {
      title: translate('menu.people.title') || 'People',
      id: 'people',
      icon: IconPeople,
    },
    // {
    //   categoryTitle: translate('menu.dev.title') || 'Dev',
    //   id: 'dev',
    //   icon: IconDev,
    // },
  ];
};

export default getItems;
