import React from 'react';

import {
  Aside,
  LogoLink,
  Nav,
  OverlayGrid,
  PoweredBy,
  ReactionsAndChat,
  Menu as StyledMenu,
  ButtonMenu as StyledButtonMenu,
  Panel as StyledPanel,
} from './styles';

import ButtonChat from 'uiVe/common/components/Button/ButtonChat';
import MetaNav from 'uiVe/components/MetaNav';
import PoweredByLink from 'uiVe/common/components/PoweredByLink';
import Reactions from 'uiVe/components/Reactions';

import { useNavService } from 'services/NavService';

import VELogo from 'assets/icons/Icon_VELogo.svg';

const OverlayInterface = () => {
  const { isMobileMenuOpen, setDesktopMenuHovered } = useNavService();

  return (
    <OverlayGrid data-mobile-menu-open={isMobileMenuOpen}>
      <Aside
        onMouseEnter={() => {
          setDesktopMenuHovered(true);
        }}
        onMouseLeave={() => {
          setDesktopMenuHovered(false);
        }}
      >
        <LogoLink href="/">
          <VELogo />
        </LogoLink>
        <StyledButtonMenu />
        <StyledMenu />
        <ReactionsAndChat>
          <Reactions />
          <ButtonChat />
        </ReactionsAndChat>
      </Aside>

      <StyledPanel />

      <Nav>
        <MetaNav />
      </Nav>

      <PoweredBy>
        <PoweredByLink />
      </PoweredBy>
    </OverlayGrid>
  );
};

export default OverlayInterface;
