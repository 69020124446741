import styled, { css } from 'styled-components';
import { mediaMax, mediaMin } from 'common/layout';

import MenuComponent from 'uiVe/components/Menu';
import PanelComponent from 'uiVe/components/Panel';
import ButtonMenuComponent from 'uiVe/common/components/Button/ButtonMenu';

export const OverlayGrid = styled.div`
  display: grid;
  grid-template-columns: 6.6rem 1fr 6.6rem;
  grid-template-rows: 6.6rem 1fr 6.6rem;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  z-index: 1;

  &[data-mobile-menu-open='false'] {
    transition-delay: 0.5s;
  }
  &[data-mobile-menu-open='true'] {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
  }

  ${mediaMin(
    'medium',
    css`
      grid-template-columns: var(--menu-width) 27.5rem 1fr 2rem;
      grid-template-columns: auto 27.5rem 1fr 2rem;
      grid-template-rows: 6rem 1fr 6rem;

      &:before {
        content: '';
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 17.5rem;
        z-index: -1;
      }
    `
  )}
`;

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  grid-column: 1 / 2;
  grid-row: 1 / 4;

  ${mediaMax(
    'small',
    css`
      display: contents;
    `
  )}

  ${mediaMin(
    'medium',
    css`
      &:before {
        content: '';
        border-right: 0.1rem solid rgba(255, 255, 255, 0.15);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: var(--menu-width);
      }
    `
  )}
`;

export const LogoLink = styled.a`
  align-items: center;
  aspect-ratio: 1;
  display: flex;
  color: white;
  justify-content: center;
  width: var(--menu-width);
  z-index: 1;

  svg {
    width: 3.6rem;
  }

  @media only screen and (max-height: 700px) {
    display: none;
  }
`;

export const ButtonMenu = styled(ButtonMenuComponent)`
  grid-column: 3 / 4;
  grid-row: 1 / 2;

  ${mediaMin(
    'medium',
    css`
      display: none;
    `
  )}
`;

export const Menu = styled(MenuComponent)`
  ${mediaMax(
    'small',
    css`
      align-self: center;
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      [data-mobile-menu-open='false'] & {
        transition-delay: 0;
      }
      [data-mobile-menu-open='true'] & {
        transition-delay: 0.5s;
        opacity: 1;
      }
    `
  )}
`;

export const Panel = styled(PanelComponent)`
  display: flex;
  flex-direction: column;
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  justify-content: end;
`;

export const Nav = styled.div`
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  justify-self: end;

  ${mediaMax(
    'small',
    css`
      grid-column: 1 / 4;
      grid-row: 3 / 4;
      justify-self: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      [data-mobile-menu-open='false'] & {
        transition-delay: 0;
      }
      [data-mobile-menu-open='true'] & {
        transition-delay: 0.5s;
        opacity: 1;
      }
    `
  )}
`;

export const ReactionsAndChat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: auto;
  z-index: 1;

  ${mediaMax(
    'small',
    css`
      grid-column: 3 / 4;
      grid-row: 2 / 4;
      transition: transform 0.3s ease-in-out;
      transform: translateX(0);

      [data-mobile-menu-open='false'] & {
        transition-delay: 0.5s;
      }
      [data-mobile-menu-open='true'] & {
        transform: translateX(100%);
      }
    `
  )}
`;

export const PoweredBy = styled.div`
  align-items: center;
  display: flex;
  grid-column: 3 / 4;
  grid-row: 3 / 4;
  justify-content: flex-end;
  width: 100%;

  @media only screen and (max-height: 700px) {
    display: none;
  }

  ${mediaMax(
    'small',
    css`
      display: none;
    `
  )}
`;
