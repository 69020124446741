import React, { useEffect, useState } from 'react';
import { PANELS, useNavService } from 'services/NavService';
import PanelChat from './PanelChat';

import { Panel as StyledPanel } from './styles';

const Panel = ({ ...others }) => {
  const [panel, setPanel] = useState(null);
  const { currentPanel } = useNavService();

  useEffect(() => {
    switch (currentPanel) {
      case PANELS.CHATS:
        setPanel(<PanelChat />);
        break;

      default:
        setPanel(null);
        break;
    }
  }, [currentPanel]);

  return <StyledPanel {...others}>{panel}</StyledPanel>;
};

export default Panel;
