import styled from 'styled-components';

export const UnderlineAnimated = styled.span`
  position: relative;

  &:before {
    content: '';
    background: var(--ltr-gradient);
    bottom: -0.5rem;
    height: 0.2rem;
    left: 0;
    position: absolute;
    width: 0;
    visibility: hidden;
    transition: all 0.4s ease-in-out;
  }

  &[data-underline='true'] {
    &:before {
      visibility: visible;
      width: 100%;
    }
  }
`;
