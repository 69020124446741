import React, { useMemo } from 'react';
import Badge from 'common/components/Badge';
import { lgBaseColors } from 'uiLivi/styles';
import { languages } from 'common/data';
import LanguageIcon from '../assets/IconLanguage.svg';

export default function LanguageBadge({ languageId }) {
  const languageName = useMemo(() => {
    const language = languages.find(lan => lan.id === languageId);
    return language.name;
  }, [languageId]);

  return <Badge text={languageName} Icon={LanguageIcon} color={lgBaseColors.red.base} withGradientBorder />;
}
