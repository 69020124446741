import create from 'utilities/zustand/create';
import userStorage from 'storage/user';
import { useModalService } from 'services/ModalService';
import { useEventStore } from 'services/EventService';
import { isMobile } from 'mobile-device-detect';
import { useUserStore } from '../UserService';
import Onboarding from '../../components/Onboarding';
import OnboardingControls from '../../components/Onboarding/OnboardingControls';
import OnboardingHost from '../../components/Onboarding/OnboardingHost';
import OnboardingOrbit from '../../components/Onboarding/OnboardingOrbit';
import OnboardingProfile from '../../components/Onboarding/OnboardingProfile';
import OnboardingUsers from '../../components/Onboarding/OnboardingUsers';

export const useOnboardingStore = create((set, get) => ({
  done: userStorage.getOnboardingDone(),
  startOnboarding: () => {
    if (!get().done) {
      get().repeatOnboarding();
    }
  },

  repeatOnboarding: () => {
    const useAvatars = useEventStore.getState().event.useAvatars;
    const isExpert = useUserStore.getState().user.role.type === 'expert';
    const onBoardingSlides = [];

    if (useAvatars) {
      onBoardingSlides.push(<OnboardingControls isMobile={isMobile} />);
    } else {
      // onBoardingSlides.push(<OnboardingOrbit />);
    }

    // defaults
    onBoardingSlides.push(<OnboardingProfile />);
    onBoardingSlides.push(<OnboardingUsers />);

    if (isExpert) {
      onBoardingSlides.push(<OnboardingHost />);
    }

    useModalService.getState().addModal(<Onboarding onBoardingSlides={onBoardingSlides} />);
  },

  finishOnboarding: () => {
    userStorage.setOnboardingDone();
    set({ done: true });
  },
}));
