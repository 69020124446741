import PersonImage from 'common/components/Person/PersonImage';

function ProfilePicture({ user }) {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
      <PersonImage src={user.profilePicture} size={'xl'} />
    </div>
  );
}

export default ProfilePicture;
