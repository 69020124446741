import { css } from 'styled-components';

export const Hover = (content, hoverEl = null) => {
  if (hoverEl) {
    return css`
      @media (any-hover: hover) {
        ${hoverEl}:hover & {
          ${content}
        }
      }
    `;
  }

  return css`
    @media (any-hover: hover) {
      &:hover {
        ${content}
      }
    }
  `;
};

export const textStyle = css`
  font-size: var(--text-size);
  line-height: 2rem;
  letter-spacing: 0.05em;
`;
export const smallTextStyle = css`
  font-size: var(--small-font-size);
  line-height: 1.4rem;
  letter-spacing: 0.05em;
`;
export const textOverflowEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const clearButtonStyles = css`
  appearance: none;
  background: transparent;
  border: 0;
  padding: 0;

  ${Hover(css`
    cursor: pointer;
  `)}
`;

export const expandMenu = css`
  overflow: hidden;
  transition: max-width 0.3s ease-in-out;

  b {
    transition: opacity 0.3s ease-in-out;
  }

  &[data-menu-hovered='false'] {
    max-width: var(--menu-width);
    transition-delay: 0.5s;
    > b {
      opacity: 0;
      transition-delay: 0.5s;
    }
  }
  &[data-menu-hovered='true'] {
    max-width: 99rem;
    transition-delay: 0s;
    > b {
      opacity: 1;
      transition-delay: 0s;
    }
  }
`;
