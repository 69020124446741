import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import { LinkContainer, LogoWrapper, StyledLink } from './styles';
import { useWindowStore } from 'services/WindowService';
import { useContentStore } from 'services/ContentService';

export default function PoweredByLink() {
  const isMobile = useWindowStore(state => state.isMobile);
  const activeContent = useContentStore(state => state.activeContent);

  const [isHovered, setIsHovered] = useState(true);
  useEffect(() => {
    setIsHovered(false);
  }, []);

  if (isMobile || activeContent) return null;

  return (
    <LinkContainer hovered={isHovered} onMouseLeave={() => setIsHovered(false)}>
      <LogoWrapper
        onClick={() => {
          if (isHovered) {
            window.open('https://www.demodern.de', '_blank');
          }
        }}
        onMouseEnter={() => setIsHovered(true)}
      >
        <Logo filled={isHovered} />
      </LogoWrapper>
      <StyledLink href="https://www.demodern.de" target="_blank" expand={isHovered}>
        powered by<b>Demodern</b>
      </StyledLink>
    </LinkContainer>
  );
}
