import React, { useEffect, useState } from 'react';
import {
  ArrowIconContainer,
  DeleteIconContainer,
  DropdownButton,
  DropdownContainer,
  DropdownOption,
  DropdownOptionList,
} from './styles';
import ArrowIcon from 'assets/icons/Icon_Arrow_Down.svg';
import { useDocumentClickListener } from 'utilities/hooks';
import DeleteIcon from 'assets/icons/Icon_Delete.svg';

export default function Dropdown({ options, callToAction, handleSelect = () => {}, initialValue, clearable }) {
  const [open, setOpen] = useState(false);
  // TODO: don't work with index, work with the select option - way easier to handle
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (initialValue) {
      const initialSelectedIndex = options.findIndex(o => o.value === initialValue);
      setSelectedIndex(initialSelectedIndex !== -1 ? initialSelectedIndex : null);
    }
  }, []);

  const handleOptionClick = index => {
    setSelectedIndex(index);
    setOpen(false);
    handleSelect(options[index].value);
  };

  const handleClear = event => {
    event.stopPropagation();
    setSelectedIndex(null);
    handleSelect(null);
  };

  useDocumentClickListener(open, () => {
    setOpen(false);
  });

  return (
    <DropdownContainer>
      <DropdownButton
        open={open}
        greyedOut={selectedIndex === null}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {selectedIndex === null ? callToAction : options[selectedIndex].name}
        {clearable && (
          <DeleteIconContainer onClick={handleClear}>
            <DeleteIcon />
          </DeleteIconContainer>
        )}
        <ArrowIconContainer>
          <ArrowIcon />
        </ArrowIconContainer>
      </DropdownButton>
      <DropdownOptionList open={open} numItems={options.length}>
        {options.map((option, index) => {
          if (option.name.length === 0) return null;
          return (
            <DropdownOption
              key={index}
              onClick={() => {
                handleOptionClick(index);
              }}
              selected={selectedIndex === index}
            >
              {option.name}
            </DropdownOption>
          );
        })}
      </DropdownOptionList>
    </DropdownContainer>
  );
}
