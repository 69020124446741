import React, { useEffect, useMemo, useState } from 'react';
import { Center, Loader, LoaderDistrictText, LoaderInfoTextBottom, LoaderInfoTextTop, TextBlock } from './styles';
import { useDistrictStore } from 'services/DistrictService';
import { useDistrictWarmupStore, WarmupPhase } from 'services/DistrictWarmupService';
import { useDebugStore } from 'storage/debug';
import CircularProgress from './CircularProgress';
import { useWindowStore } from '../../../services/WindowService';
import LoaderBackgroundImage from './LoaderBackgroundImage';
import fromCdn from '../../../utilities/cdn';
import LoaderHintDisplay from './LoaderHintDisplay';

const fadeDuration = 500;
const fadeDelay = 10;

function useDistrictProgress() {
  const phase = useDistrictWarmupStore(state => state.phase);
  const loadingProgress = useDistrictWarmupStore(state => state.loadingProgress);
  const enteringProgress = useDistrictWarmupStore(state => state.enteringProgress);
  return useMemo(() => {
    switch (phase) {
      case WarmupPhase.IDLE: {
        return 0;
      }
      case WarmupPhase.LOADING: {
        return loadingProgress * 0.45 + 0.05;
      }
      case WarmupPhase.ENTERING: {
        return enteringProgress * 0.45 + 0.5;
      }
      case WarmupPhase.INIT: {
        return 0.95;
      }
      case WarmupPhase.DONE: {
        return 1;
      }
    }
  }, [phase, loadingProgress, enteringProgress]);
}

function useNextDistrict() {
  const nextMapId = useDistrictWarmupStore(state => state.nextMapId);
  return useDistrictStore(state => state.districts.find(d => d.room === nextMapId));
}

export default function VisualLoader() {
  const progress = useDistrictProgress();
  const nextDistrict = useNextDistrict();
  const phase = useDistrictWarmupStore(state => state.phase);
  const isLoaded = phase === WarmupPhase.DONE;
  const isMobile = useWindowStore(state => state.isMobile);
  const isStatsEnabled = useDebugStore(state => state.getStatsEnabled());

  const [hide, setHide] = useState(false);
  const [bgImage, setBgImage] = useState(null);
  const [hints, setHints] = useState();

  const getProgress = () => {
    return (
      (progress * 100)
        .toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .toString() + '%'
    );
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        if (!isLoaded) setHide(isLoaded);
      },
      isLoaded ? fadeDuration + fadeDelay : 0
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [phase]);

  useEffect(() => {
    if (nextDistrict && nextDistrict.loading) {
      if (nextDistrict.loading.image) {
        const img = fromCdn(nextDistrict.loading.image);
        setBgImage(img);
      } else {
        setBgImage(null);
      }
      if (nextDistrict.loading.hints) {
        setHints(nextDistrict.loading.hints);
      } else {
        setHints(null);
      }
    } else {
      setBgImage(null);
      setHints(null);
    }
  }, [nextDistrict]);

  return (
    <Loader
      isStatsEnabled={isStatsEnabled}
      hide={hide}
      isLoaded={isLoaded}
      fadeDuration={fadeDuration}
      fadeDelay={fadeDelay}
    >
      <LoaderBackgroundImage isLoaded={isLoaded} bgImage={bgImage} />
      {hints && !isLoaded && <LoaderHintDisplay isLoaded={isLoaded} hints={hints} />}
      <TextBlock isLoaded={isLoaded}>
        <LoaderInfoTextTop>ENTERING</LoaderInfoTextTop>
        <LoaderDistrictText>{nextDistrict ? nextDistrict.title : ''}</LoaderDistrictText>
        <LoaderInfoTextBottom>{getProgress()}</LoaderInfoTextBottom>
      </TextBlock>
      <Center size={!isMobile ? 450 : 250}>
        <CircularProgress progress={progress * 100} size={!isMobile ? 450 : 250} />
      </Center>
    </Loader>
  );
}
