import styled from 'styled-components';

import { Button } from 'uiVe/common/components/Button/styles';

import { clearButtonStyles } from 'uiVe/styles/mixins';

export const ButtonUnderline = styled(Button)`
  ${clearButtonStyles};

  align-items: center;
  display: flex;
  color: white;
  gap: 1rem;
  position: relative;
  white-space: nowrap;

  svg {
    width: 1.8rem;
  }
`;
