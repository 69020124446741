import styled from 'styled-components';

import ButtonUnderline from 'uiVe/common/components/Button/ButtonUnderline';

export const ButtonSound = styled(ButtonUnderline)`
  color: red;
  svg {
    opacity: 0.5;
  }
`;
