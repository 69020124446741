import create from 'utilities/zustand/create';

export const PANELS = Object.freeze({
  LOCATIONS: 'locations',
  SCHEDULE: 'schedule',
  PEOPLE: 'people',
  CHATS: 'chats',
});

const PanelInitialState = {
  [PANELS.LOCATIONS]: false,
  [PANELS.SCHEDULE]: false,
  [PANELS.PEOPLE]: false,
  [PANELS.CHATS]: true,
};

export const useNavService = create((set, get) => ({
  isMobileMenuOpen: false,
  isDesktopMenuHovered: false,
  currentPanel: PANELS.CHATS,
  panelState: new Map(Object.entries(PanelInitialState)),

  toggleMobileMenu: () => {
    set({ isMobileMenuOpen: !get().isMobileMenuOpen });
  },
  setDesktopMenuHovered: value => {
    set({ isDesktopMenuHovered: value });
  },
  openPanel: panel => {
    const updatedPanelState = new Map(Object.entries(PanelInitialState));
    updatedPanelState.set(panel, true);
    set({ panelState: updatedPanelState, currentPanel: panel });
  },
  closeAllPanels: () => {
    set({ panelState: new Map(Object.entries(PanelInitialState)), currentPanel: null });
  },
}));
