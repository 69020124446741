import PasswordInput from 'common/components/PasswordInput';
import PasswordInputWithCheck from 'common/components/PasswordInputWithCheck';
import { useTranslate } from 'services/TranslationService/hooks';

function EditPassword({
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  newPasswordCopy,
  setNewPasswordCopy,
}) {
  const translate = useTranslate();
  return (
    <>
      <PasswordInput
        placeholder={translate('profile.password.current') || 'Current password'}
        password={currentPassword}
        setPassword={setCurrentPassword}
      />
      <PasswordInputWithCheck
        placeholder={translate('profile.password.new') || 'New password (at least 6 characters)'}
        password={newPassword}
        setPassword={setNewPassword}
        checkPlaceholder={translate('profile.password.check') || 'Re-enter new password'}
        checkPassword={newPasswordCopy}
        checkSetPassword={setNewPasswordCopy}
      />
    </>
  );
}

export default EditPassword;
