import { useLoader, useThree } from '@react-three/fiber';
import { PMREMGenerator } from 'three';
import { useEffect } from 'react';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js';
import create from 'utilities/zustand/create';
import { useDistrictStore } from 'services/DistrictService';
import fromCdn from 'utilities/cdn';

export const useCubemapRGBMDebugStore = create(() => ({
  exrDebugFile: null,
}));

export default function CubemapRGBM() {
  const { gl, scene } = useThree();

  const district = useDistrictStore(state => state.district);
  const exrDebugFile = useCubemapRGBMDebugStore(state => state.exrDebugFile);

  const exr = useLoader(
    EXRLoader,
    fromCdn(
      exrDebugFile
        ? exrDebugFile
        : district?.exr
        ? district?.exr
        : 'https://assets.virtual-experience.demodern.com/shared/environment/Beer_Pub_2k.exr'
    )
  );

  useEffect(() => {
    const pmremGenerator = new PMREMGenerator(gl);
    pmremGenerator.compileCubemapShader();
    const cubeMap = pmremGenerator.fromEquirectangular(exr);
    scene.environment = cubeMap.texture;
  }, [exr]);

  return null;
}
