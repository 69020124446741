import styled, { css } from 'styled-components';
import { mediaMax, styledScrollbar } from 'common/layout';

export const OuterContainer = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  max-height: ${props => props.maxHeight};
  ${props => (props.takeFullHeight ? `height: ${props.maxHeight}` : null)};
  ${mediaMax(
    'small',
    css`
      height: 100%;
      max-height: 100%;
    `
  )}
`;

export const HeaderContainer = styled.div``;

export const BodyContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  position: relative;
  ${props =>
    !props.preventScroll
      ? css`
          overflow-y: auto;
          ${styledScrollbar};
        `
      : null}
  ${props =>
    props.showShadow
      ? css`
          box-shadow: inset 0px -9px 10px -8px rgba(0, 0, 0, 0.55);
        `
      : null}
`;

export const FooterContainer = styled.div``;
