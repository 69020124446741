import React from 'react';

import { ExpandableInput as StyledExpandableInput, Input, Icon } from './styles';

const ExpandableInput = ({ icon = null, placeholder = '', buttonTitle, onSubmit }) => {
  return (
    <StyledExpandableInput>
      <Input role="textbox" contentEditable placeholder={placeholder} />
      {icon && (
        <Icon title={buttonTitle} onClick={onSubmit}>
          {icon}
        </Icon>
      )}
    </StyledExpandableInput>
  );
};

export default ExpandableInput;
