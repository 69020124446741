import { css } from 'styled-components';

const variables = css`
  :root,
  :before,
  :after {
    --font-size: 1.3rem;
    --small-font-size: 1rem;

    --grey-medium: #6b6b6b;

    --color-font-strong: white;
    --color-font-light: #f7f7f5;
    --color-bg-base: #191919;
    --color-bg-highlight: black;
    --color-accent-base: #7c73f3;
    --color-accent-highlight: #e877ae;
    --color-secondary-base: #e28e33;
    --color-warn: #e877ae;
    --color-error: #7c73f3;
    --color-success: #62a60a;

    --min-hit-size: 4.4rem;

    --menu-width: 7.5rem;

    --ltr-gradient: linear-gradient(120deg, var(--color-accent-highlight), var(--color-accent-base));
  }
`;

export default variables;
