import styled from 'styled-components';
import { clearButtonStyles } from 'uiVe/styles/mixins';

export const ExpandableInput = styled.div`
  align-items: center;
  background-color: white;
  border-radius: 1.2rem;
  color: #000;
  display: flex;
  font-size: 1.2rem;
  line-height: 2rem;
  padding: 1.4rem;
  width: 100%;
`;

export const Input = styled.span`
  align-items: center;
  display: flex;
  flex-grow: 1;
  max-height: 6rem;
  overflow: hidden auto;
  width: 100%;

  &:focus {
    outline: none;
  }
  &:empty::before {
    content: 'press enter ↩ to type';
    color: #ababab;
  }

  > * {
    margin: 0;
  }
`;

export const Icon = styled.button`
  ${clearButtonStyles};

  align-self: end;
  color: var(--color-accent-base);
  line-height: 0;
  margin-bottom: 0.2rem;
  width: 1.8rem;
`;
