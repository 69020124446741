import styled, { css } from 'styled-components';
import { center, colors, easings, sharedMenuButtonStyle } from 'uiLivi/styles';

export const backButtonStyle = css`
  > div {
    height: 25%;
    transform: translate(0, -27%) rotate(45deg);
    &:last-child {
      transform: translate(0, 27%) rotate(-45deg);
    }
  }
`;

export const minimizeButtonStyle = css`
  > div {
    height: 25%;
    transform: rotate(90deg);
    &:last-child {
      display: none;
    }
  }
`;

export const StyledCloseButton = styled.div`
  ${sharedMenuButtonStyle};
  transform: none;
  background-color: ${props => (props.noBackground ? 'transparent' : props.black ? 'black' : colors.background.base)};
  pointer-events: all;
  cursor: pointer;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  ${props => props.center && center.absolute()};
  ${props =>
    props.position !== null
      ? `
          position: absolute;
          top: ${'top' in props.position ? props.position.top : 'auto'};
          right: ${'right' in props.position ? props.position.right : 'auto'};
          bottom: ${'bottom' in props.position ? props.position.bottom : 'auto'};
          left: ${'left' in props.position ? props.position.left : 'auto'};
        `
      : null};
  > div {
    transition: height 0.25s ${easings.easeOutStrong}, transform 0.25s ${easings.easeOutStrong}, background 0.1s;
    height: 33.3%;
    position: absolute;
    border-radius: 1.5px;
    background-color: ${props => (props.xColor ? props.xColor : colors.font.light)};
    transform: rotate(45deg);
    &:last-child {
      transform: rotate(-45deg);
    }
  }
  ${props => (props.isBackButton ? backButtonStyle : null)};
  ${props => (props.isMinimizeButton ? minimizeButtonStyle : null)};
  ${props => props.animation && props.animation};
  &:hover {
    > div {
      background-color: ${colors.accent.base};
    }
  }
`;

const CloseButton = props => {
  const {
    handler,
    size = 32,
    position = null,
    noBackground = false,
    center = false,
    isBackButton = false,
    isMinimizeButton = false,
    black = false,
    withAnimation = false,
    xColor = false,
  } = props;
  return (
    <StyledCloseButton
      xColor={xColor}
      key={Date.now()}
      onClick={handler}
      size={size}
      position={position}
      noBackground={noBackground}
      center={center}
      isBackButton={isBackButton}
      isMinimizeButton={isMinimizeButton}
      black={black}
      animation={withAnimation}
    >
      <div />
      <div />
    </StyledCloseButton>
  );
};

export default CloseButton;
