import TimetableMenu from './SubMenu/TimetableMenu';
import PeopleMenu from './SubMenu/PeopleMenu';
import ProfileMenu from './SubMenu/ProfileMenu';
import LocationMenu from './SubMenu/LocationMenu';
import DevMenu from './SubMenu/DevMenu';
import HelpMenu from './SubMenu/HelpMenu';
import { useTranslationStore } from 'services/TranslationService';

import IconCalender from 'uiLivi/assets/icons/Icon_Calender.svg';
import IconPeople from 'uiLivi/assets/icons/Icon_People.svg';
import IconProfile from 'uiLivi/assets/icons/Icon_Profile.svg';
import IconDev from 'uiLivi/assets/icons/Icon_Dev.svg';
import IconHelp from 'uiLivi/assets/icons/Icon_Help.svg';
import IconLocation from 'uiLivi/assets/icons/Icon_Districts.svg';

export default function getItems(user) {
  const translate = key => useTranslationStore.getState().translate(key, user.language);
  return [
    {
      categoryTitle: translate('menu.location.title') || 'Locations',
      id: 'locations',
      icon: IconLocation,
      active: false,
      subComponentFunction: LocationMenu,
      wide: false,
    },
    {
      categoryTitle: translate('menu.schedule.title') || 'Schedule',
      id: 'timetable',
      icon: IconCalender,
      active: false,
      subComponentFunction: TimetableMenu,
      wide: true,
    },
    {
      categoryTitle: translate('menu.people.title') || 'People',
      id: 'people',
      icon: IconPeople,
      active: false,
      subComponentFunction: PeopleMenu,
    },
    {
      categoryTitle: translate('menu.profile.title') || 'Profile',
      id: 'profile',
      icon: IconProfile,
      active: false,
      subComponentFunction: ProfileMenu,
    },
    {
      categoryTitle: translate('menu.help.title') || 'Help',
      id: 'help',
      icon: IconHelp,
      active: false,
      subComponentFunction: HelpMenu,
    },
    {
      categoryTitle: translate('menu.dev.title') || 'Dev',
      id: 'dev',
      icon: IconDev,
      active: false,
      isDevContent: true,
      subComponentFunction: DevMenu,
    },
  ];
}
