import { colors } from '../../../../uiLivi/styles';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  transform: rotate(-90deg);
`;

export default function CircularProgress(props) {
  const {
    size = 450,
    progress = 0,
    trackWidth = 3,
    indicatorWidth = 3,
    trackColor = colors.accent.base,
    indicatorColor = colors.accent.highlight,
    indicatorCap = 'round',
  } = props;

  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <>
      <Wrapper size={size}>
        <svg style={{ width: size, height: size }}>
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            opacity={0.4}
            strokeWidth={trackWidth}
          />
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            strokeLinecap={indicatorCap}
          />
        </svg>
      </Wrapper>
    </>
  );
}
