import React from 'react';

import { ButtonExpand as StyledButtonExpand } from './styles';

import IconExpand from 'uiVe/assets/icons/Icon_Expand.svg';
import IconCollapse from 'uiVe/assets/icons/Icon_Collapse.svg';

const ButtonExpand = ({ expanded, onClick, ...others }) => (
  <StyledButtonExpand onClick={onClick} {...others}>
    {expanded ? 'collapse' : 'expand'}
    {expanded ? <IconCollapse /> : <IconExpand />}
  </StyledButtonExpand>
);

export default ButtonExpand;
