import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import variables from './variables';

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${variables};

  html {
    font-size: 62.5%;
  }
  body {
    font-family: 'demodern', 'sans-serif';
    font-size: 1.3rem;
    letter-spacing: 0.05em;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
  }
  * {
    touch-action: pan-x pan-y;
  }
  *, *:before, *:after {
    box-sizing: border-box;
  }

  b {
    font-weight: 
  }
  a {
    color: inherit;
  }
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }

  ul, li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .application {
    position: fixed;
    width: 100%;
    height: 100%;
  }
`;
