import React from 'react';

import ButtonUnderline from 'uiVe/common/components/Button/ButtonUnderline';
import SoundButton from 'uiVe/common/components/Button/ButtonSound';

import { StyledMetaNav } from './styles';

import IconProfile from 'uiVe/assets/icons/Icon_Profile.svg';

const MetaNav = () => {
  const handleClick = () => {};
  return (
    <StyledMetaNav>
      <ButtonUnderline icon={<IconProfile />} text="Profile" onClick={handleClick} />
      <ButtonUnderline text="Help" onClick={handleClick} />
      <SoundButton />
    </StyledMetaNav>
  );
};

export default MetaNav;
