import { useTraverse } from '../hooks';

export default function useConfigureShadows() {
  useTraverse(() => {
    return o => {
      o.castShadow = true;
      o.receiveShadow = true;
    };
  });
}
