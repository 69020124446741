import React from 'react';

import { useSoundStore } from 'services/SoundService';
import { useTrackingStore } from 'services/TrackingService';

import { ButtonSound as StyledButtonSound } from './styles';

import IconSound from 'uiVe/assets/icons/Icon_Sound.svg';

const ButtonSound = () => {
  const { enabledByUser, enabledBySystem } = useSoundStore(state => ({
    enabledByUser: state.enabled,
    enabledBySystem: state.isPlayAllowed,
  }));

  const enabled = enabledByUser && enabledBySystem;

  const handleClick = () => {
    if (!enabledBySystem) return;

    useTrackingStore.getState().trackEvent({
      category: 'Sound',
      action: enabled ? 'Mute' : 'Unmute',
    });
    useSoundStore.getState().toggle();
  };

  return (
    <StyledButtonSound
      data-enabled={enabled}
      icon={<IconSound />}
      text={enabled ? 'Sound on' : 'Sound off'}
      onClick={handleClick}
    />
  );
};

export default ButtonSound;
