import React, { useState } from 'react';

import UnderlineAnimated from 'uiVe/common/components/UnderlineAnimated';

import { ButtonUnderline as StyledButtonUnderline } from './styles';

const ButtonUnderline = ({ icon = null, text, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledButtonUnderline
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon && icon}
      <UnderlineAnimated showUnderline={isHovered}>{text}</UnderlineAnimated>
    </StyledButtonUnderline>
  );
};

export default ButtonUnderline;
