import React, { useState } from 'react';

import { LinkContainer, Logo, ExpandableText } from './styles';

import DemodernLogo from 'assets/icons/Icon_Demodern_Logo.svg';

export default function PoweredByLink() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <LinkContainer
      href="https://www.corporate-metaverse.com/"
      data-sound-action="click"
      data-hovered={isHovered}
      target="_blank"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Logo data-hovered={isHovered}>
        <DemodernLogo />
      </Logo>
      <ExpandableText>
        powered by<b>ve.</b>
      </ExpandableText>
    </LinkContainer>
  );
}
