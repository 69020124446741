import {
  IconContainer,
  InstructionHeadline,
  InstructionText,
  InstructionTopline,
  InstructionVisualBox,
  InstructionVisualColumn,
  InstructionVisualContainer,
  InstructionWrapper,
} from '../styles';
import IconUsers from '../assets/users.png';
import React from 'react';

export default function OnboardingUsers() {
  return (
    <InstructionWrapper>
      <InstructionTopline>Get Started</InstructionTopline>
      <InstructionHeadline>Make it personal</InstructionHeadline>
      <InstructionText>Click on other avatars to start a chat and read personal information.</InstructionText>
      <InstructionVisualContainer>
        <InstructionVisualColumn grow>
          <InstructionVisualBox>
            <IconContainer icon={IconUsers} />
          </InstructionVisualBox>
        </InstructionVisualColumn>
      </InstructionVisualContainer>
    </InstructionWrapper>
  );
}
