import React from 'react';
import { usePlayerStore } from 'services/PlayerService';

import { StyledReactions, ReactionButton } from './styles';
import { useUserStore } from 'services/UserService';
import { useChatStore } from 'services/ChatService';

const emojis = [
  { icon: '👍', type: 'like', index: 3 },
  { icon: '♥️', type: 'love', index: 2 },
  { icon: '👏', type: 'clap', index: 4 },
  { icon: '😄', type: 'smile', index: 5 },
  { icon: '🎉', type: 'celebration', index: 6 },
  { icon: '👋', type: 'greet', index: 1 },
];

const Reactions = () => {
  const playerActions = usePlayerStore.getState().playerActions;
  const sendEmoji = useChatStore(state => state.sendEmoji);
  const user = useUserStore(state => state.user);

  const handleEmojiClick = emoji => {
    sendEmoji(emoji.type, user.id);
    playerActions.setReaction(emoji.index);
  };

  return (
    <StyledReactions>
      {emojis.map(emoji => (
        <li key={emoji.type}>
          <ReactionButton onClick={() => handleEmojiClick(emoji)}>{emoji.icon}</ReactionButton>
        </li>
      ))}
    </StyledReactions>
  );
};

export default Reactions;
