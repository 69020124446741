import React from 'react';

import { useUserStore } from 'services/UserService';
import { useTranslationStore } from 'services/TranslationService';

import { ButtonChat as StyledButtonChat } from './styles';

import { useNavService, PANELS } from 'services/NavService';

import IconChat from 'uiVe/assets/icons/Icon_Chat.svg';

const ButtonChat = () => {
  const user = useUserStore(state => state.user);
  const translate = key => useTranslationStore.getState().translate(key, user.language);
  const category = translate('chat.cta') || 'Chat';
  const { isDesktopMenuHovered, openPanel } = useNavService();

  return (
    <StyledButtonChat
      aria-label={category}
      data-menu-hovered={isDesktopMenuHovered}
      onClick={() => openPanel(PANELS.CHATS)}
    >
      <span>
        <IconChat />
      </span>
      <b>{category}</b>
    </StyledButtonChat>
  );
};

export default ButtonChat;
