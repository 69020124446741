import styled, { css } from 'styled-components';
import { colors, easings, zIndex } from 'uiLivi/styles';
import { mediaMax } from 'common/layout';

export const marginTopAndButton = 15;

export const MenuContainer = styled.div`
  z-index: ${props => (props.aboveAll ? zIndex.modalAndNotifications + 1 : zIndex.menu)};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  pointer-events: none;
  user-select: none;
  ${mediaMax(
    'small',
    css`
      z-index: ${zIndex.menu};
    `
  )}
`;

export const MainMenuContainer = styled.div`
  position: relative;
  float: left;
  pointer-events: all;
  left: 0;
  top: ${marginTopAndButton}px;
  max-height: calc(100vh - 30px);
  overflow-y: auto;
  transition: transform 0.35s ${easings.easeOutStrong}, background 0.35s;
  transform: translateX(${props => (props.show ? 0 : -101)}%);
  ${mediaMax(
    'small',
    css`
      position: absolute;
      top: 0;
      max-height: none;
      height: 100%;
      background-color: ${colors.background.base};
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    `
  )}
`;

export const MenuItemContainer = styled.div`
  ${mediaMax(
    'small',
    css`
      display: flex;
      align-content: center;
      align-self: flex-start;
      flex-flow: column;
      margin: 0 auto;
    `
  )};
`;
