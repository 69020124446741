import { BadgeContainer, TextContainer } from './styles';

export default function Badge({ text, Icon, color, withGradientBorder = false }) {
  return (
    <BadgeContainer color={color} withGradientBorder={withGradientBorder}>
      <Icon />
      <TextContainer>{text}</TextContainer>
    </BadgeContainer>
  );
}
