import styled from 'styled-components';

export const PanelChat = styled.div`
  background: rgba(255, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 45rem;
  transition: max-height 0.3s ease-in-out;

  &[data-expanded='true'] {
    max-height: 999rem;
  }
`;

export const ChatContent = styled.div`
  flex-grow: 1;
  padding: 0 3rem;
`;
export const ChatInput = styled.div`
  padding: 0 3rem 1.5rem;
`;
