import styled from 'styled-components';

export const Panel = styled.div`
  background: rgba(255, 0, 0, 0.1);
`;

export const PanelButtons = styled.div`
  align-items: center;
  color: white;
  display: flex;
  justify-content: end;
`;
