import { BlockTitle, ProfileBlock, ProfileInfo, ProfileInfoSection } from '../styles';
import UserView from '../UserView';
import ProfilePicture from '../ProfilePicture';
import HBFLayout from 'common/components/HBFLayout';
import { Button } from 'common/components/Button';
import IconSend from 'assets/icons/Icon_Send.svg';
import { useUserStore } from 'services/UserService';
import ProfileName from '../ProfileName';
import { MODE_FITTING } from 'services/PlayerService/constants';
import { usePlayerStore } from 'services/PlayerService';
import { useChatStore } from 'services/ChatService';
import { marginTopAndButton } from '../../../../styles';
import { closeButtonSize } from '../../../styles';
import { useTranslate } from 'services/TranslationService/hooks';
import { ButtonFooterContainer, LogOutLink } from './styles';
import VeTextLink from 'common/components/VeTextLink';
import { useEventStore } from 'services/EventService';

function TitleJobAndCountry({ user }) {
  let location = user.country ? user.country : '';
  if (location !== '') {
    if (user.state) location += ', ' + user.state;
  } else if (user.state) {
    location = user.state;
  }
  return user.title || user.company || location ? (
    <ProfileInfoSection>
      <BlockTitle>{user.title}</BlockTitle>
      <ProfileInfo>{user.company}</ProfileInfo>
      <ProfileInfo>{location}</ProfileInfo>
    </ProfileInfoSection>
  ) : null;
}

function ProfileView({ user, isSelf, playerIsExpert, tabs, setEditMode }) {
  const translate = useTranslate();
  const useAvatars = useEventStore(state => state.event.useAvatars);

  if (user === undefined) return null;

  const showProfessionSection = user.title || user.company || user.country;
  const showUserSection = isSelf || playerIsExpert || user.linkedInUrl;

  const isExpertProfile = user.role.type === 'expert';
  const footer = isSelf ? (
    <ButtonFooterContainer>
      <LogOutLink
        onClick={() => {
          useUserStore.getState().logout();
        }}
      >
        {translate('profile.logout.button') || 'Log out'}
      </LogOutLink>
      <Button
        data-track-category="Profile"
        data-track-action={'Edit'}
        fullWidth
        accent
        onClick={() => {
          setEditMode(true);
        }}
        veStyle
      >
        {translate('profile.edit.button') || 'Edit Profile'}
      </Button>

      <div
        style={{
          width: '100%',
          textAlign: 'center',
          margin: '1.5em 0 0.5em 0',
        }}
      >
        {useAvatars && (
          <VeTextLink
            onClick={() => {
              usePlayerStore.getState().setMode(MODE_FITTING);
              usePlayerStore.setState({ isLookingAtFairPassOfUserId: null });
            }}
          >
            {translate('avatar.edit.button') || 'Edit Avatar'}
          </VeTextLink>
        )}
      </div>
    </ButtonFooterContainer>
  ) : (
    <Button
      fullWidth
      accent
      noRadius
      veStyle
      data-track-category="Profile"
      data-track-action={isExpertProfile ? 'ApproachExpert' : 'Approach'}
      onClick={() => {
        useChatStore.getState().start([user.id]);
      }}
    >
      <IconSend width={18} height={18} />
      {translate('profile.sayhello') || 'Say Hello!'}
    </Button>
  );

  const body = (
    <>
      <ProfileBlock highlighted centered>
        <ProfilePicture user={user} />
        <ProfileName user={user} />
      </ProfileBlock>

      {(showProfessionSection || showUserSection) && (
        <ProfileBlock>
          {showProfessionSection && <TitleJobAndCountry user={user} />}
          {showUserSection && <UserView user={user} playerIsExpert={playerIsExpert} isSelf={isSelf} />}
        </ProfileBlock>
      )}
    </>
  );

  return (
    <HBFLayout
      header={tabs}
      body={body}
      bodyShadow={isSelf}
      footer={footer}
      fullHeightMinus={marginTopAndButton * 2 + closeButtonSize}
    />
  );
}

export default ProfileView;
