import styled from 'styled-components';
import React from 'react';

const TouchArea = styled.div`
  padding: 5px 10px;
  cursor: pointer;
`;

const Bullet = styled.div`
  background-color: rgba(255, 255, 255, ${props => (props.selected ? 1 : 0)});
  border: 1px solid #ffffff;
  height: 7px;
  width: ${props => (props.selected ? 25 : 7)}px;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
`;

const PaginationContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  bottom: 25px;
`;

export default function Pagination({ slideCount, slideIndex, gotoSlide }) {
  const bullets = Array.from(Array(slideCount).keys());
  return (
    <PaginationContainer>
      {bullets.map((_, index) => {
        return (
          <TouchArea
            key={index}
            onClick={() => {
              gotoSlide(index);
            }}
          >
            <Bullet selected={index === slideIndex} />
          </TouchArea>
        );
      })}
    </PaginationContainer>
  );
}
